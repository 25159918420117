import React,{useState,useEffect} from 'react';
// import GlobalStyle from '.././__Global.style';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import GlobalStyle from '../../__Global.style';
import Panda from 'imgs/zj/pandas.jpg';

const Main = styled.main`
  max-width: 40rem;
  background: white;
  margin: 0 auto;
  padding:5rem 0 0;
  width:100%;
  text-align:center;
  height:100%;
  position:relative;
  z-index:3
`;
const Listens = () => {
    const [data,setData]=useState({});
    useEffect(()=>{
        const url = window.location.href;
        let res = {};
        if (url.indexOf('?') > -1) {
        let params = url.split('?')[1];
        let paramsArr = params.split('&');

        for (let i = 0; i < paramsArr.length; i++) {
            let str = paramsArr[i].split('=');
            res[str[0]] = str[1];
        }
        }
        setData(res);
        console.log(res,'hrhrhrhrhr');
    },[]);

    return (
        <Main>
            <Helmet title={decodeURI(data.title)||'心力UP听一听'} />
            <GlobalStyle />
            <img src={Panda} alt="" style={{width:'100%',height:'100%',position:'fixed',top:0,left:0,zIndex:'-1'}}/>
             {!!data.audioUrl&&<audio style={{ with:'100%'}} controls="controls">
                 <source src={data.audioUrl} type="audio/mpeg"/>
             </audio>}
             {/* :'音频资源尚未开放，请等待' */}
             {!!data.videoUrl&&<video
             src={data.videoUrl}
             controls={true}
             loop
             style={{
                maxWidth:'40rem',
                width:'100%',
                marginTop:'12rem'
             }}
             />}
        </Main>

    );
};

export default Listens;